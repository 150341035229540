<template>
  <div>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          width="100%"
          height="100vh"
          permanent
          color="#ffffff"
        >
          <v-layout wrap>
            <v-flex xs12 text-left py-2 text-capitalize align-self-center>
              <v-list dark dense v-for="main in appMenu" :key="main._id">
                <v-layout wrap>
                  <v-flex xs12 py-2>
                    <v-list-group
                      no-action
                      :value="false"
                      :active-class="`black--text`"
                    >
                      <template v-slot:activator>
                        <v-list-item-title
                          style="
                            font-size: 13px;
                            letter-spacing: 3px;
                            cursor: pointer;
                            font-family: poppinsregular;
                            color:black !important;
                          "
                          >{{ main.name }}</v-list-item-title
                        >
                      </template>
                      <v-flex xs12 py-2 px-4>
                        <div style="border-bottom: 1px solid #ffffff25"></div>
                      </v-flex>
                      <template v-for="sub in main.subMenu">
                        <v-flex xs12 text-left pl-6 :key="sub._id">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link :to="sub.route">
                                <span
                                  :style="
                                    $route.path == sub.route
                                      ? {
                                          'font-family': 'poppinsbold',
                                          'color': '#000',
                                        }
                                      : {
                                          'font-family': 'poppinsregular',
                                          'color': '#000',
                                        }
                                  "
                                  style="
                                    font-size: 13px;
                                    color: #ffffff;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                  "
                                >
                                  {{ sub.name }}
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-2 px-4 pl-6 :key="sub._d">
                          <div style="border-bottom: 1px solid #ffffff25"></div>
                        </v-flex>
                      </template>
                    </v-list-group>
                    <!-- <v-list-group v-else> -->
                  </v-flex>
                </v-layout>
                <!-- </v-list-group> -->
              </v-list>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #ffffff'
              : 'background-color: #f6fff9'
          "
        >
          <v-flex xs12 pa-0>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : true"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view :key="$route.fullPath"></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import template from '../../template.vue';
import store from "./../../store";
export default {
  // components: { template },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: true,

      menus: [
        {
          name: "Dashboard",
          subMenu: [
            {
              name: "Dashboard",
              route: "/",
            },
          ],
        },

        {
          name: "Products",
          subMenu: [
            {
              name: "Products",
              route: "/products",
            },
            {
              name: "Add Product",
              route: "/addProduct",
            },

             
          ],
        },

        {
          name: "Purchase",
          subMenu: [
            {
              name: "Purchase",
              route: "/Admin/purchaseReports",
            },
          ],
        },

         {
          name: "Quotations",
          subMenu: [
            {
              name: "Quotations",
              route: "/Admin/Quotations",
            },
          ],
        },
        {
          name: "Settings",
          subMenu: [
            {
              name: "Terms and Conditions",
              route: "/Admin/Policies?u=Terms",
            },
            {
              name: "Privacy Policy",
              route: "/Admin/Policies?u=Privacy",
            },
            {
              name: "Cancellation Policy",
              route: "/Admin/Policies?u=Cancel",
            },
            {
              name: "Contact Us",
              route: "/Admin/Policies?u=Contact",
            },
          ],
        },
      ],
    };
  },
  computed: {
    appType() {
      return store.state.userType;
    },
    appMenu() {
      return this.menus;
    },
    navItems() {
      return this.menus;
    },
    userData() {
      return store.state.userData;
    },
  },
  methods: {
    changeRoute(item) {
      if (this.$route.query.styl != item._id) {
        this.$router.push({
          path: "/Products",
          query: {
            styl: item._id,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
          },
        });
      } else {
        this.$router.push({
          path: "/Products",
          query: {
            styl: null,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
          },
        });
      }
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
</style>
