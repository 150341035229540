var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg2":"","hidden-md-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"clipped":"","width":"100%","height":"100vh","permanent":"","color":"#ffffff"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","py-2":"","text-capitalize":"","align-self-center":""}},_vm._l((_vm.appMenu),function(main){return _c('v-list',{key:main._id,attrs:{"dark":"","dense":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-2":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false,"active-class":"black--text"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticStyle:{"font-size":"13px","letter-spacing":"3px","cursor":"pointer","font-family":"poppinsregular","color":"black !important"}},[_vm._v(_vm._s(main.name))])]},proxy:true}],null,true)},[_c('v-flex',{attrs:{"xs12":"","py-2":"","px-4":""}},[_c('div',{staticStyle:{"border-bottom":"1px solid #ffffff25"}})]),_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,attrs:{"xs12":"","text-left":"","pl-6":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"13px","color":"#ffffff","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == sub.route
                                    ? {
                                        'font-family': 'poppinsbold',
                                        'color': '#000',
                                      }
                                    : {
                                        'font-family': 'poppinsregular',
                                        'color': '#000',
                                      })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":"","py-2":"","px-4":"","pl-6":""}},[_c('div',{staticStyle:{"border-bottom":"1px solid #ffffff25"}})])]})],2)],1)],1)],1)}),1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg10":"","pt-12":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'
            ? 'background-color: #ffffff'
            : 'background-color: #f6fff9'),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-0":""}},[_c('v-card',{attrs:{"min-height":"100vh","color":_vm.$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-4":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }